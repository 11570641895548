import clsx from 'clsx'
import { useFormik } from 'formik'
import * as React from 'react'
import { FC, useState, useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import * as Yup from 'yup'
// import { AuditsListLoading } from '../components/loading/AuditsListLoading'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
// import Multiselect from 'multiselect-react-dropdown'
import CreatableSelect from 'react-select/creatable'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
// import { createCity, createAudit, getCities, updateAudit,uniqueCode } from '../core/_requests'
// import { setMessage } from '../../../../../store/alertMessage/actions'
 import { useIntl } from 'react-intl'
import { createAudit, uniqueCode, updateAudit } from '../core/_requests'
import { setMessage } from 'src/store/alertMessage/actions'
import { AuditsListLoading } from '../components/loading/AuditsListLoading'


type Props = {
  isAuditLoading: boolean
  audit: any
}



const AuditEditModalForm: FC<Props> = ({ audit, isAuditLoading }) => {

  // const cityList = useSelector((state: any) => state.commonList.cityList)
   const intl = useIntl()
  
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<any>(false)
 const [codeError, setCodeError] = useState<any>(false)

  const optionOfCrDr: any = [
    { value: 'CR', label: `${intl.formatMessage({ id: 'CREDIT' })}` },
    { value: 'DR', label: `${intl.formatMessage({ id: 'DEBIT' })}` }
  ];

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const checkPartiesCodeUnique = async (code:any) => {
    try {
      const response = await uniqueCode(code);
      if(response?.responseData?.isUnique == true){
        setCodeError(true)
      }
      else{
        setCodeError(false)
      }
      return response?.responseData?.isUnique;

    } catch (error) {
      // Handle the error as needed
      console.error('Error checking parties code uniqueness', error);
      return false;
    }
  };


  const AuditManagementData = {
    parties_code: '',
    parties_name: '',
    parties_phone: '',
    parties_opening_balance: '',
    opening_bal_crordr: '',  
  }
  const editAuditSchema = Yup.object().shape({
    parties_code: Yup.string()
      .min(5, `${intl.formatMessage({ id: 'ERRORS.MINIMUMCUSTOMERCODELENGTH' })}`)
      .max(5, `${intl.formatMessage({ id: 'ERRORS.MAXIMUMCUSTOMERCODELENGTH' })}`)
      .required(`${intl.formatMessage({ id: 'CUSTOMER.CODE.IS.REQUIRED' })}`),
      parties_name: Yup.string()
      .min(3, `${intl.formatMessage({ id: 'ERRORS.MINIMUMCUSTOMERNAMELENGTH' })}`)
      .max(50, `${intl.formatMessage({ id: 'ERRORS.MAXIMUMCUSTOMERNAMELENGTH' })}`)
      .required(`${intl.formatMessage({ id: 'CUSTOMER.NAME.IS.REQUIRED' })}`),
      parties_phone: Yup.string()
      // .matches(/^[6789]\d{9}$/, `${intl.formatMessage({id: 'INVALID.PHONE.NUMBER.FORMAT'})}`)
      .test('length', `${intl.formatMessage({ id: 'INVALID.PHONE.NUMBER.LENGTH' })}`, (val: any) => val && val.length === 10)
      .test('format', `${intl.formatMessage({ id: 'INVALID.PHONE.NUMBER.FORMAT' })}`, (val: any) => /^[6789]\d{9}$/.test(val))
      .required(`${intl.formatMessage({ id: 'INVALID.PHONE.NUMBER.FORMAT' })}`),
      parties_opening_balance: Yup.string().required(`${intl.formatMessage({ id: 'ERRORS.OPENINGBALANCEREQUIRED' })}`),
      opening_bal_crordr: Yup.string().required(`${intl.formatMessage({ id: 'ERRORS.CRORDRREQUIRED' })}`),
    
  });
  

  const formik = useFormik({
    initialValues: AuditManagementData,
    validationSchema: editAuditSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let sendData = JSON.parse(JSON.stringify(values))
      setSubmitting(true)
      try {
        setLoading(true)
        if (itemIdForUpdate) {
          await updateAudit(sendData, itemIdForUpdate)
            .then((data: any) => {
              if (data?.statusCode === 200 || data?.responseStatus === 200) {
                dispatch(setMessage('success', data?.message ?? data?.responseMessage))
                refetch()
                cancel(true)
              } else {
                dispatch(
                  setMessage(
                    'error',
                    data?.responseData?.msg ? data?.responseData?.msg : data?.responseMessage
                  )
                )
              }
              setLoading(false)
              setSubmitting(false)
            })
            .catch((error: any) => {
              if (error?.response?.data?.responseData?.msg) {
                dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
              } else if (error?.response?.data?.responseMessage) {
                dispatch(setMessage('error', error?.response?.data?.responseMessage))
              } else {
                dispatch(setMessage('error', error?.message))
              }
              setLoading(false)
              setSubmitting(false)
            })
        } else {
          await createAudit(sendData)
            .then((data: any) => {
              if (data?.statusCode === 200 || data?.responseStatus === 200) {
                dispatch(setMessage('success', data?.message ?? data?.responseMessage))
                refetch()
                cancel(true)
              } else {
                dispatch(
                  setMessage(
                    'error',
                    data?.responseData?.msg ? data?.responseData?.msg : data?.responseMessage
                  )
                )
              }
              setLoading(false)
              setSubmitting(false)
            })
            .catch((error: any) => {
              if (error?.response?.data?.responseData?.msg) {
                dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
              } else if (error?.response?.data?.responseMessage) {
                dispatch(setMessage('error', error?.response?.data?.responseMessage))
              } else {
                dispatch(setMessage('error', error?.message))
              }
              setLoading(false)
              setSubmitting(false)
            })
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })


  React.useEffect(() => {
    if (itemIdForUpdate) {
          
      formik.setFieldValue('parties_code', audit?.responseData?.party_code)
      formik.setFieldValue('parties_name', audit?.responseData?.party_name)
      formik.setFieldValue('parties_phone', audit?.responseData?.party_phone)
      formik.setFieldValue('parties_opening_balance', audit?.responseData?.party_opening_balance)
      formik.setFieldValue('opening_bal_crordr', audit?.responseData?.opening_bal_crordr)
    }
  }, [itemIdForUpdate])

  return (
    <>
      <form
        id='kt_modal_add_Audit_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column  mb-5 me-n7 pe-4 ps-2'
          id='kt_modal_add_Audit_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_Audit_header'
          data-kt-scroll-wrappers='#kt_modal_add_Audit_scroll'
          data-kt-scroll-offset='300px'

        >
          <Row className='mb-8'>
                      <Col lg={6}>
              <label className='required fw-bold fs-6 mb-2'>
                {/* <FormattedMessage id='LABELS.CODE' /> */}
                Code
                </label>
              <input
                placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.ENTERCODE' })}
                type='text'
                name='CCode'
                className='form-control form-control-solid'
                autoComplete='off'
                maxLength={5}
                onChange={(e: any) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
                    formik.setFieldValue('parties_code', inputValue.trimStart());
                       checkPartiesCodeUnique(inputValue.trimStart());                     
                  }
                }}
                value={formik?.values?.parties_code}
              />
              {formik.touched.parties_code && formik.errors.parties_code &&(
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.parties_code as any}</span>
                </div>
              )}
              {codeError? (<div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{codeError ? `${intl.formatMessage({ id: 'PARTY_CODE_ALREADY_EXISTS' })}`: ''}</span>
                </div>): ('')}
            </Col>
            <Col lg={6}>
              <label className='required fw-bold fs-6 mb-2'>
                {/* <FormattedMessage id='LABELS.NAME' /> */}
                Name
                </label>
              <input
                placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.ENTERNAME' })}
                type='text'
                name='CName'
                className='form-control form-control-solid '
                autoComplete='off'
                maxLength={100}
                onChange={(e: any) => {
                  formik.setFieldValue('parties_name', e.target.value.trimStart())
                }}
                value={formik?.values?.parties_name}

              />
              {formik.touched.parties_name && formik.errors.parties_name && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.parties_name as any}</span>
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-8'>
            <Col lg={6}>
              <label className='required fw-bold fs-6 mb-2'> Mobile No</label>
              <input
                placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.ENTERMOBILENUMBER' })}
                className='form-control form-control-solid'
                type='text'
                name='contact'
                maxLength={10}
                autoComplete='off'
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  const key = e.key;
                  const isNumeric = /^[0-9]$/.test(key);
                  const isSpecialKey =
                    key === 'Backspace' ||
                    key === 'Delete' ||
                    key === 'ArrowLeft' ||
                    key === 'ArrowRight' ||
                    key === 'ArrowUp' ||
                    key === 'ArrowDown' ||
                    (e.ctrlKey && (key === 'a' || key === 'c' || key === 'v' ));  // Handle Ctrl + A
                    if (!isNumeric && !isSpecialKey && key !== 'Tab') { // Exclude 'Tab' key
                      e.preventDefault();
                    }
                
                    if (key === 'Tab' || !isNumeric && !isSpecialKey) {
                     
                      const nextInput = e.currentTarget.nextSibling as HTMLInputElement | null;
                      if (nextInput) {
                        nextInput.focus();
                        e.preventDefault(); // Prevent default Tab behavior (e.g., moving to the next focusable element)
                      }
                    }
                  }}
                 

                onChange={(e: any) => {
                  formik.setFieldValue('parties_phone', e.target.value.trimStart())
                }}
                value={formik?.values?.parties_phone}
              />
              {formik.touched.parties_phone && formik.errors.parties_phone && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.parties_phone as any}</span>
                </div>
              )}
            </Col>
           
            <Col lg={6}>
              <label className='required fw-bold fs-6 mb-2'>
                {/* <FormattedMessage id='LABELS.OPENINGBALANCE' /> */}
                Opening Balance
                </label>
              <input
                placeholder={intl.formatMessage({ id: 'LABELS.OPENINGBALANCE' })}
                className={clsx('form-control form-control-solid ')}
                type='text'
                name='Opening Balance'
                maxLength={7}
                autoComplete='off'
                style={{ backgroundColor: itemIdForUpdate ? 'hsl(0, 0%, 95%)' : 'white' }}
                disabled={itemIdForUpdate ? true : false}
                onChange={(e: any) => {
                  let inputValueBal = e.target.value.trimStart();

                  let numericValueBal = inputValueBal.replace(/\D/g, '');
                  formik.setFieldValue('parties_opening_balance', numericValueBal)
                  
                }}
                value={formik?.values?.parties_opening_balance}
              />
              {formik.touched.parties_opening_balance && formik.errors.parties_opening_balance && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.parties_opening_balance as any}</span>
                </div>
              )}
            </Col>
          </Row>

          <Row className='mb-8'>
          <Col lg={6}>
              <label className='required fw-bold fs-6 mb-2'>
                {/* <FormattedMessage id='LABELS.DRCR' /> */}
                Credit/Debit
                </label>
              <Select
                className='custom_select mb-3 '
                options={optionOfCrDr}
                isDisabled={itemIdForUpdate ? true : false}
                onChange={(e: any) => {
                  formik.setFieldValue('opening_bal_crordr', e.value.trimStart());
                }}
                value={optionOfCrDr.find((option: any) => option.value === formik?.values?.opening_bal_crordr)}
              />
            </Col>       
                </Row>

            <Row className='mb-8'>
           
          </Row>        
        </div>
        <div className='text-end pe-4 mb-10 pt-5'>
          <button
            type='submit'
            className='btn me-3 text-white'
            data-kt-Audits-modal-action='submit'
            disabled={formik.isSubmitting}
          >
            <span className='indicator-label'> {loading ? `${intl.formatMessage({ id: 'PLEASE.WAIT' })}` : `${intl.formatMessage({ id: 'BUTTONS.SUBMIT' })}`}</span>
          </button>
          <button
            type='reset'
            onClick={() => {
              cancel()
            }}
            className='btn btn-light me-3'
            data-kt-Audits-modal-action='cancel'
          >
            CANCEL
            {/* <FormattedMessage id='BUTTONS.CANCEL' /> */}

          </button>
        </div>
      </form>
      {(formik.isSubmitting || isAuditLoading) && <AuditsListLoading />}
    </>
  )
}

export { AuditEditModalForm }
