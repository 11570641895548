/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {CustomerLedger} from './_models'
import {getCustomersLedger} from './_requests'
import {useSelector} from 'react-redux'

const QueryResponseContext = createResponseContext<CustomerLedger>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest() as any
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const cityList = useSelector((state: any) => state.commonList.cityList)
  const isAdmin = cityList?.roleInfo?.id === 1 || cityList?.roleInfo?.id === 2
  const isGeneralListPermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.general_report_list === true

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.SERVICECENTERS_LIST}-${query}`,
    () => {
      return getCustomersLedger(query, state?.uuid)     },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseDataLedger = () => {
  const {response} = useQueryResponse() as any
  if (!response || !response?.responseData || !response?.responseData?.ledger_entries) {
    return []
  }
  

  return response?.responseData?.ledger_entries ?? []
}

const useQueryResponseDataMessage = () => {
  const {response} = useQueryResponse() as any
  if (!response || !response?.responseData || !response?.responseData?.message) {
    return []
  }

  return response?.responseData?.message ?? []
}

// const useQueryResponseData = () => {
//   const {response} = useQueryResponse() as any
//   if (!response || !response?.responseData) {
//     return []
//   }

//   return response?.responseData || []
// }

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse() as any
  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination || []
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseDataLedger,
  useQueryResponseLoading,
  useQueryResponsePagination,
  useQueryResponseDataMessage,
}
