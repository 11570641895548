import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {configuration} from '../../../../../../configuration'
const API_URL = configuration.apiUrl
const PARTY_URL = `${API_URL}/parties`
const DeleteCustomer_URL = `${API_URL}/messages/deleteTransaction`
const CustomerLedger_URL = `${API_URL}/parties/partyLedger`
const tallyLedger = `${API_URL}/ledgerentries/tallyLedger`
const HO_DETAIL_URL = `${API_URL}/ledgerentries/getHoDetail`
const Message_URL = `${API_URL}/messages`
const MONDAYFINALBYID_URL = `${API_URL}/messages/approveByLedger`
const ONLY_PARTY_URL = `${API_URL}/parties/onlyPartys`
// const getCustomers = (query: string): Promise<any> => {
//   return axios.get(`${Customer_URL}?${query}&sort=customer_code&order=asc`).then((d: AxiosResponse<any>) => d.data)
// }
const  getUsers = (query: string): Promise<any> => {
  return axios.get(`${ONLY_PARTY_URL}`).then((d: AxiosResponse<any>) => d.data)
}
const getCustomersLedger = (query: string, id: any): Promise<any> => {
  return axios.get(`${CustomerLedger_URL}/${id}?${query}`).then((d: AxiosResponse<any>) => d.data)
}

const getHoDetail = (): Promise<any> => {
  return axios.get(`${HO_DETAIL_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getCustomerById = (id: any): Promise<any> => {
  return axios
    .get(`${PARTY_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

// const createCustomer = (data: any): Promise<any> => {
//   return axios
//     .post(Customer_URL, data)
//     .then((response: AxiosResponse<Response<any>>) => response.data)
// }
const TallyLedger = (data: any, id: any): Promise<any> => {
  return axios
    .patch(`${tallyLedger}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteCustomerLedger = (id: any): Promise<any> => {
  return axios
    .delete(`${DeleteCustomer_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}
const MondayFinalById = (id: any): Promise<any> => {
  return axios
    .get(`${MONDAYFINALBYID_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const TallyAllMessages = (data: any, id: any): Promise<any> => {
  return axios
    .patch(`${tallyLedger}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteSelectedCustomers = (CustomerIds: Array<ID>): Promise<void> => {
  const requests = CustomerIds.map((id) => axios.delete(`${PARTY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getMessageById = (id: any): Promise<any> => {
  return axios
    .get(`${Message_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

export {
  getUsers,
  // createCustomer,
  deleteCustomerLedger,
  deleteSelectedCustomers,
  getCustomerById,
  // getCustomers,
  MondayFinalById,
  TallyLedger,
  getCustomersLedger,
  getHoDetail,
  getMessageById,
  TallyAllMessages
}
