/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {logoutApi} from 'src/app/modules/auth/core/_requests'
import {setMessage} from 'src/store/alertMessage/actions'
import {removeAuth} from 'src/store/authDetail/actions'
import {getNotifications} from '../../header/_requests'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import HeaderNotificationsMenu from 'src/_metronic/partials/layout/header-menus/HeaderNotificationsMenu'
import {Navbar} from '../../header/Navbar'
// Import your HeaderNotificationsMenu component

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const btnIconClass = 'svg-icon-1'
const redDotClass = 'red-dot'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const cityList = useSelector((state: any) => state?.commonList?.cityList)
  const isAdmin = cityList?.roleInfo?.id === 1 || cityList?.roleInfo?.id === 2
  const isPartyListPermission =
    cityList?.roleInfo?.id === 3 &&
    (cityList?.userPermissionInfo[0]?.party_is_list == true ||
      cityList?.userPermissionInfo[0]?.party_is_create == true ||
      cityList?.userPermissionInfo[0]?.party_is_edit === true ||
      cityList?.userPermissionInfo[0]?.party_is_view == true ||
      cityList?.userPermissionInfo[0]?.party_is_delete === true ||
      cityList?.userPermissionInfo[0]?.party_export === true)

  const isMessagePermission =
    cityList?.roleInfo?.id === 3 &&
    (cityList?.userPermissionInfo[0]?.message_is_list === true ||
      cityList?.userPermissionInfo[0]?.message_is_create === true ||
      cityList?.userPermissionInfo[0]?.message_is_edit === true ||
      cityList?.userPermissionInfo[0]?.message_is_view === true ||
      cityList?.userPermissionInfo[0]?.message_is_delete === true ||
      cityList?.userPermissionInfo[0]?.message_export === true)
  const isUserPermission =
    cityList?.roleInfo?.id === 3 &&
    (cityList?.userPermissionInfo[0]?.user_is_list === true ||
      cityList?.userPermissionInfo[0]?.user_is_create === true ||
      cityList?.userPermissionInfo[0]?.user_is_edit === true ||
      cityList?.userPermissionInfo[0]?.user_is_view === true ||
      cityList?.userPermissionInfo[0]?.user_is_delete === true ||
      cityList?.userPermissionInfo[0]?.user_export === true)
  const isChangePasswordPermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.change_password === true
  const isAuditLogsPermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.audit_logs === true

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = async () => {
    try {
      await logoutApi()
      dispatch(setMessage('success', 'Logout Successfully'))
      localStorage.clear()
      dispatch(removeAuth())
      navigate('/auth/login')
    } catch (err) {
      dispatch(setMessage('success', 'Logout Successfully'))
      localStorage.clear()
      dispatch(removeAuth())
      navigate('/auth/login')
    }
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  // Notification-related states and functions
  const [data, setData] = useState<any>([])
  const [isNotificationMenuOpen, setNotificationMenuOpen] = useState(false)

  const fetchData = async () => {
    try {
      await getNotifications()
        .then((data: any) => {
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            setData(data.responseData)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const redDotBlink = !data?.every((notification: any) => notification?.read === 1)

  return (
    <>
      {(isAdmin || isPartyListPermission) && (
        <SidebarMenuItem
          to='/party/list-party'
          title={intl.formatMessage({id: 'PARTIES'})}
          icon='/media/icons/duotune/general/gen049.svg'
        />
      )}
      {(isAdmin || isMessagePermission) && (
        <SidebarMenuItem
          to='/message/list-messages'
          title={intl.formatMessage({id: 'MESSAGES'})}
          icon='/media/icons/duotune/communication/com007.svg'
        />
      )}
      {(isAdmin || isUserPermission) && (
        <SidebarMenuItem
          to='/user/list-users'
          title={intl.formatMessage({id: 'USERS'})}
          icon='/media/icons/duotune/communication/com006.svg'
        />
      )}
      {(isAdmin ||
        (cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.general_report_list)) && (
        <SidebarMenuItem
          to='/partyledger/list-partyledger'
          title={intl.formatMessage({id: 'CUSTOMER.LEDGER'})}
          icon='/media/icons/duotune/finance/fin001.svg'
        />
      )}
      {(isAdmin || isUserPermission) && (
        <SidebarMenuItem
          to='/import-excel/list-import-excel'
          title={intl.formatMessage({id: 'IMPORT.EXCEL'})}
          icon='/media/icons/duotune/files/fil021.svg'
        />
      )}
      {(isAdmin ||
        (cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.cr_dr_report)) && (
        <SidebarMenuItemWithSub
          to='/report'
          title={intl.formatMessage({id: 'REPORTS'})}
          icon='/media/icons/duotune/general/gen005.svg'
          fontIcon='bi-person'
        >
          {(isAdmin ||
            (cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.cr_dr_report)) && (
            <SidebarMenuItem
              to='/report/generalledger/list-generalledger'
              title={intl.formatMessage({id: 'GENERAL.LEDGER'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {(isAdmin || isChangePasswordPermission || isAuditLogsPermission) && (
        <SidebarMenuItemWithSub
          to='/Setting'
          title={intl.formatMessage({id: 'SETTINGS'})}
          icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-person'
        >
          {(isAdmin || isChangePasswordPermission) && (
            <SidebarMenuItem
              to='/settings/change-psw'
              title={intl.formatMessage({id: 'CHANGE.PASSWORD'})}
              icon='/media/icons/duotune/general/gen038.svg'
            />
          )}
          {(isAdmin || isAuditLogsPermission) && (
            <SidebarMenuItem
              to='/settings/auditlog/list-audit'
              title={intl.formatMessage({id: 'AUDIT.PAGE'})}
              icon='/media/icons/duotune/files/fil006.svg'
            />
          )}

          {/* <SidebarMenuItem
            to="#"
            title="Notification"
            icon="/media/icons/duotune/files/notifications1.svg"
            onClick={() => {
              setNotificationMenuOpen(!isNotificationMenuOpen);
            }}
          >
            {redDotBlink && <span className={redDotClass}></span>}
          </SidebarMenuItem>
            {isNotificationMenuOpen && <HeaderNotificationsMenu showRedDot={fetchData} />}
          <SidebarMenuItem
            to="#"
            title="Full screen"
            icon="/media/icons/duotune/files/expand.svg"
            onClick={() => {
              toggleFullScreen();
            }}
          /> */}
        </SidebarMenuItemWithSub>
      )}
      <SidebarMenuItem
        to='#'
        title={intl.formatMessage({id: 'LOGOUT'})}
        icon='/media/icons/duotune/arrows/arr002.svg'
        onClick={() => {
          logout()
        }}
      />
      <HeaderNotificationsMenu showRedDot={fetchData} />
    </>
  )
}

export {SidebarMenuMain}
